.pres2{
    padding : auto;
    background-color:  rgb(240, 234, 234);
    margin-top: 50px;
}


.cont2 h1{
    
    color: rgb(196, 28, 28);
    font-size: 30px;
    text-align: left;
    padding: 15px;
    font-family: 'Nunito Sans',sans-serif;
    
}
.cont2 p{
    text-align: justify;
    text-align: justify;
    color: rgb(131, 124, 124);
    line-height: 2;
    word-wrap: break-word;
    text-align-last: justify;
    word-spacing: 2px;
    font-weight: 300; 
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
}
