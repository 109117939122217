.formation h3{
    color:rgb(90, 85, 85);
}
.formation h5{
    color: rgb(196, 28, 28);
}


.formation h1{
    margin-top: 30px;
    color: rgb(196, 28, 28);
    font-size: 30px;
    text-align: center;
    font-family: 'Nunito Sans',sans-serif; 
    animation: text 2s ease-in-out;
    }
    @keyframes text {
        0%{
            letter-spacing: 10px;
        }
        100%{
            letter-spacing: 0px;
        }
        
    }
.for1{
    justify-content: center;
}

.formation p{
    color: rgb(48, 47, 47);
    text-align: justify;
    text-align: justify;
    font-family: 'Source Sans Pro', sans-serif;   
}
