.col1{
    background-color: rgb(241, 238, 238);
}
.col1 h4{
    text-align: center;
    size: 50px;
    color: rgb(196, 28, 28);
    padding: 50px;
}
.contact h1{
    margin-top: 30px;
    color: rgb(196, 28, 28);
    font-size: 30px;
    text-align: center;
    font-family: 'Nunito Sans',sans-serif;
}
.contact p{
    color: black;
    text-align: center;
    size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
}
.contact p b{
    color: gray;
    text-decoration: underline;
}

.conta {
    background-color:rgb(187, 185, 185) ;
}

.col1 p{
    color: black;
    text-align: justify;
    text-align-last:left;
    size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
}
.contact Button{
  background-color: rgb(230, 225, 225);
  color: rgb(196, 28, 28)
}
.contact Button :hover{
    color: rgb(196, 28, 28);
  }

  .mapouter{
    height: 811px;
    width: 100%;
    float: none;
    position: relative;
    border-radius: 0px !important;
    margin: 0px auto !important;
    overflow: hidden;
    }

.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height: 811px;
    width: 100%;
    float: none;
    position: relative;
    border-radius: 0px !important;
    margin: 0px auto !important;
    overflow: hidden;
}