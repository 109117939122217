.analyse p{
    color: rgb(48, 47, 47);
    text-align: justify;
    text-align: justify;
    font-family: 'Source Sans Pro', sans-serif;
}
.analyse ul li{
    font-family: 'Source Sans Pro', sans-serif;
    size:20px
}
.analyse h1{
    text-align: center;
}