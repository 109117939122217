h1 {
    color: rgb(196, 28, 28);
    font-size: 40px;
    text-align: center;
    font-family: 'Nunito Sans',sans-serif;   
    animation: text 2s ease-in-out;
}

@keyframes text {
    0%{
        letter-spacing: 10px;
    }
    100%{
        letter-spacing: 0px;
    }
}

p{
    line-height: 2rem;
}

h5{
    color: rgb(68, 160, 154);
    margin-top: -20px;
    margin-left: 30px;
}
h3{
    color: rgb(112, 59, 59);
}

.arrow-right {
    width: 15px;
    height: 15px;
    background-color: rgb(68, 160, 154);

    clip-path: polygon(0 0, 0 100%, 100% 50%);
}