.migration img{
    margin-top: 20%;
    height: 350px;
    -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;}
.migration :hover img{
    -webkit-transform: scale(1.3);
	transform: scale(1.3);
}