.scrollTop {
    position: fixed; 
    bottom: 20px;
    height: 20px;
    z-index: 1000;
    size: 30px;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.8;
    width: 30px;
    right: 60px;
    color: rgb(196, 28, 28);
    height: 30px;
    border-radius: 15px;
  } 