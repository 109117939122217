.pres1{
    padding-top: 50px;
}
.cont{
    background-color: white;
}
.text1 h1{
    
    color: rgb(196, 28, 28);
    font-size: 30px;
    text-align: left;
    font-family: 'Nunito Sans',sans-serif;
    animation: text 2s ease-in-out;
}
@keyframes text {
    0%{
        letter-spacing: 10px;
    }
    100%{
        letter-spacing: 0px;
    }
    
}
.text2 p{
    text-align: justify;
    text-align: justify;
    color: rgb(131, 124, 124);
    line-height: 2;
    word-wrap: break-word;
    text-align-last: justify;
    word-spacing: 2px;
    font-weight: 300; 
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
    animation: text2 1s ease-in-out;
}

@keyframes text2 {
    0%{
        transform: scale(0);
    }
    100%{
        letter-spacing: 0px;
    }
}


