.support{
    background-color: rgb(255, 255, 255);
}
.support h1{
    color: rgb(196, 28, 28);
    font-size: 40px;
    text-align: left;
    font-family: 'Nunito Sans',sans-serif;   
    animation: text 2s ease-in-out;
    }
    @keyframes text {
        0%{
            letter-spacing: 10px;
        }
        100%{
            letter-spacing: 0px;
        }
        
    }


.support p{
    color: black;
    text-align: justify;
    text-align-last: left;
    size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
}

.support h4{
    padding: auto;
    
    
}


.support ul li{ 
    color: rgb(90, 85, 85);
    padding: auto;
    text-align: justify;
    text-align: justify;
    font-family: 'Source Sans Pro', sans-serif;
   
}
.par1{
    background-color: rgb(241, 238, 238);
    
}