 .navbar {
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 40px;
}


.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  padding-top: 6px;
}

.navbar-logo {
  color: rgb(10, 10, 10);
  justify-self: start;
  margin-left:0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: -70px;
}

/* .nav-item {
  height: 80px;
} */

.nav-links {
  color: rgb(54, 52, 52);
  display: flex;
  font-size: 16px;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: 'Amatic SC', cursive;
font-family: 'Amiri', serif;
font-family: 'Cormorant Garamond', serif;
font-family: 'Crimson Text', serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'PT Sans', sans-serif;
}

.nav-links:hover {
  color:rgb(196, 28, 28) ;
  border-bottom: 4px solid rgb(196, 28, 28);
  transition: all 0.2s ease-out;
  text-decoration: none;
}




.submenu{
  position:absolute;
  width: 220px;
  top: 100%;
  left: 0%;
  background-color: white;
  border-radius:0px;
  display: none;
  text-align: start;
  padding-left: 0rem;
  border-top: 3px solid gray;
}



.submenu li{
   position: relative;
    margin: 0px;
    float: none;
    display: list-item;
}



.submenu2{
  position: absolute;
  left: 100%;
  top: 0%;
  width: 220px;
  border-radius: 0px;
  background-color: white;
  display: none;
  text-align: start;
  border-left: 3px solid gray;
  
}

.submenu li{
  list-style: none;
  padding: 15px;
}

.submenu2 li{
  list-style: none;
  padding: 15px;
  margin-left: -10px;
  
}
.submenu li a{
  color: black;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Amatic SC', cursive;
font-family: 'Amiri', serif;
font-family: 'Cormorant Garamond', serif;
font-family: 'Crimson Text', serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'PT Sans', sans-serif;
  text-align: start;
  width: 100%;
  display: table;
}

.submenu li a:hover{
  color: rgb(196, 28, 28);
  font-size: 17px;
  text-decoration: none;
  font-family: 'Amatic SC', cursive;
font-family: 'Amiri', serif;
font-family: 'Cormorant Garamond', serif;
font-family: 'Crimson Text', serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'PT Sans', sans-serif;
}

.dropdown li:hover .submenu{
  display: block;
}


.submenu li:hover .submenu2{
  display: block;
}




.navbar-brand{
  padding-top: 0px;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  margin-top: -80px;
}


.fa-bars {
  color: rgb(202, 28, 28);
}

.nav-links-mobile {
  display: none;
  color: rgb(0, 0, 0);
}

.menu-icon {
  display: none;
}


@media screen and (max-width: 1130px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-top: auto;
    overflow-y:auto;
  }

  .nav-menu.active {
    background: rgb(202, 198, 198);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
  }

  .nav-links {
    text-align: left;
    padding: 1rem;
    width: 100%;
    display: table;
    z-index: 1;
  }
  .nav-item{
    margin-left: -32px;
    height:max-content;
  }

  .nav-links:hover {
    color:rgb(196, 28, 28);
    border-bottom:none;

    border-radius: 0;
    box-shadow: none;
  }
  
  .navbar-logo {
    position: absolute;
    left: 0;
    transform: translate(25%, 50%);
  }

  .navbar-brand{
    position: absolute;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: rgb(202, 28, 28);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    line-height: 6rem;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    padding: 14px 20px;
    transition: all 0.3s ease-out;

  }

  .nav-links-mobile:hover {
    color: #242424;
    transition: 250ms;
    border-style: none;
  } 

   .dropdown ul{
    position: initial;
    border: 3px solid transparent;
    border-left-color: red;
    background-color: rgb(202, 198, 198);
    height: initial;


        top: initial;
        left: initial;
        transform: initial;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        padding: 0;

    
  }

  .dropdown ul ::before{
    display: none;
  }

  .nav-menu li:hover .dropdown ul{
    opacity: 1;
    visibility: visible;
    max-height: initial;
    display: block;
  }

  
 
}



















 